const data = [
    {
        projectName: 'Documated',
        projectDescription: `Documated, a business website specializing in delivering 
        cutting-edge technical services with a focus on automation. The site is designed 
        to provide businesses with streamlined, user-friendly access to our automation solutions.`,
        technologies: [
            'React',
            'TypeScript',
            'ChakraUI',
            'NodeJS',
            'Micro services',
            'Lambda',
            'API Gateway',
            'AWS SES',
        ],
    },
];

export default data;
